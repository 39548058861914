export const useDialogStore = defineStore('dialog', {
  state: () => {
    return {
      confirm: {
        visible: false,
      },
      confirmEmergency: {
        visible: false,
      },
      anonymousRequest: {
        visible: false,
      },
      confirmRemoveContact: {
        visible: false,
      },
      confirmRequestAccept: {
        visible: false,
        title: '',
        text: '',
        acceptText: '',
        accept: () => {},
      },
      requestForward: {
        visible: false,
        id: null,
        selected: null,
        service_id: null,
      },
      chooseAssistant: {
        visible: false,
        id: null,
        selected: null,
      },
    };
  },

  actions: {
    open(dialogName) {
      this[dialogName].visible = true;
    },
    close(dialogName) {
      this[dialogName].visible = false;
    },
    update({ dialogName, data }) {
      this[dialogName] = {
        ...this[dialogName],
        ...data,
      };
    },
  },

  getters: {
  },
});
